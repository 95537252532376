import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Styles/ChapterPages.css';
import config from "../config";

const ChapterPages = () => {
  const { chapter_id } = useParams();
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    fetch(`${config.apiBaseUrl}/fullmarks-user/home/fetchchapterpages.php?chapter_id=${chapter_id}`)
      .then(response => response.json())
      .then(data => setPages(data))
      .catch(error => console.error('Error fetching chapter pages:', error));
  }, [chapter_id]);

  const handleNextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="container-fluid bg-white shadow-lg p-3 mb-5 rounded">
      <br />
      <div className='d-flex justify-content-between'>
        Home/Class/Chapter/{chapter_id}
      </div>
      <br />
      <div className="page-navigation-container">
        {currentPage > 0 && (
          <span className="nav-arrow left-arrow" onClick={handlePrevPage}>
            &larr; {/* Left Arrow */}
          </span>
        )}

        <div className="page-content">
          {pages.length > 0 ? (
            <div className="page-container">
              {pages[currentPage].page_content_type === 'image' ? (
                <img
                  src={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/book_pages/${pages[currentPage].image_path}`}
                  alt={`Page ${currentPage + 1}`}
                  className="page-image img-fluid rounded"
                />
              ) : (
                <div className="page-text pre-wrap">
                  <img
                    src={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/book_pages/${pages[currentPage].image_path}`}
                    alt={`Page ${currentPage + 1}`}
                    className="page-image img-fluid rounded"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="no-pages-found">No pages found</div>
          )}
        </div>

        {currentPage < pages.length - 1 && (
          <span className="nav-arrow right-arrow" onClick={handleNextPage}>
            &rarr; {/* Right Arrow */}
          </span>
        )}
      </div>
    </div>
  );
};

export default ChapterPages;
