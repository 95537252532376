import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Modal, Container, Row, Col, Card } from 'react-bootstrap';
import config from '../../config';
import AuthContext from './AuthContext';
import LoadingContext from './LoadingContext';
import Teacherregister from './Teacherregister';
import Loader from './Loader';
import '../Styles/LoginReg.css';
import banner from '../Assets/banner-4.jpg';

const Teacherlogin = () => {
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const { loading } = useContext(LoadingContext);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('email', loginEmail);
    formData.append('password', loginPassword);

    try {
      const response = await fetch(`${config.apiBaseUrl}/fullmarks-user/user/login.php`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (data.success) {
        login({ id: data.id, name: data.name, email: loginEmail, role: 'teacher', pic: data.profile_pic });
        setLoginError('');
        navigate(`/teacher-dashboard/${data.id}`);
      } else {
        setLoginError('Username/password not found');
      }
    } catch (error) {
      console.error('Error:', error);
      setLoginError('Error during login');
    }
  };

  const handleForgotPassword = async () => {
    if (newPassword !== confirmPassword) {
      setForgotPasswordError('Passwords do not match');
      return;
    }

    const formData = new FormData();
    formData.append('email', forgotEmail);
    formData.append('newPassword', newPassword);

    try {
      const response = await fetch(`${config.apiBaseUrl}/fullmarks-user/user/forgotpassword.php`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (data.success) {
        alert('Password reset successful');
        setShowForgotPasswordModal(false);
        setForgotPasswordError('');
      } else {
        setForgotPasswordError('Failed to reset password');
      }
    } catch (error) {
      console.error('Error:', error);
      setForgotPasswordError('Error resetting password');
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="banner-container2">
                <img className='banner-window' src={banner} alt="Banner" />
                <div className="centered-text">Login/Registration (Teacher)</div>
            </div>

      <Container className=" mt-5 d-flex justify-content-center mb-5">
        <Row className="w-100 mt-3">
          <Col md={6} className="mx-auto">
            <Card className="shadow-lg">
              <Card.Header className="text-center" style={{ backgroundColor: "#0A1172", color: "white" }}>
                <h4>{isSignUp ? 'Teacher Registration' : 'Teacher Login'}</h4>
              </Card.Header>
              <Card.Body>
                <div className="d-flex justify-content-center mb-4">
                  <div className="toggle-buttons" >
                    <button
                      className={`toggle-btn ${!isSignUp ? 'active' : ''}`}
                      onClick={() => setIsSignUp(false)}
                    >
                      Sign In
                    </button>
                    <button
                      className={`toggle-btn ${isSignUp ? 'active' : ''}`}
                      onClick={() => setIsSignUp(true)}
                    >
                      Sign Up
                    </button>
                    <div className={`toggle-slider ${isSignUp ? 'right' : 'left'}`} />
                  </div>
                </div>
                {isSignUp ? (
                  <Teacherregister />
                ) : (
                  <Form onSubmit={handleLogin}>
                    <Form.Group controlId="loginEmail" className='mt-3'>
                      <hr></hr>
                      <Form.Label className='fw-bold text-dark'>Email*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter email"
                        value={loginEmail}
                        onChange={(e) => setLoginEmail(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="loginPassword" className='mt-4'>
                      <Form.Label className='fw-bold text-dark'>Password*</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter password"
                        value={loginPassword}
                        onChange={(e) => setLoginPassword(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <div className='d-flex justify-content-end mt-3'>
                      <Button type="submit" className="btn-custom">Login</Button>
                    </div>
                    <div className='mt-2 d-flex justify-content-end'>
                      <span onClick={() => setShowForgotPasswordModal(true)} style={{ cursor: 'pointer', color: '#0A1172' }}>Forgot Password?</span>
                    </div>
                    {loginError && <div className="text-danger text-center mt-3">{loginError}</div>}
                  </Form>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={showForgotPasswordModal} onHide={() => setShowForgotPasswordModal(false)}>
        <Modal.Header closeButton style={{ backgroundColor: "#0A1172", color: "white" }}>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="forgotEmail">
              <Form.Label>Email/Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your email or username"
                value={forgotEmail}
                onChange={(e) => setForgotEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="newPassword" className='mt-3'>
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="confirmPassword" className='mt-3'>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
            {forgotPasswordError && <div className='text-danger mt-2'>{forgotPasswordError}</div>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowForgotPasswordModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleForgotPassword}>
            Reset Password
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Teacherlogin;
